import React from 'react'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {store, persistor} from './src/state/store'

// eslint-disable-next-line react/display-name,react/prop-types
export default ({element}) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return (
    <PersistGate loading={null} persistor={persistor}>
      <Provider store={store}>{element}</Provider>
    </PersistGate>
  )
}
