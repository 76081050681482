import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  role: null,
  email: null,
}

export const calculatorReducer = createSlice({
  name: 'calculator',
  initialState,
  reducers: {},
})

export const {updateUserRole} = calculatorReducer.actions
export default calculatorReducer.reducer
