exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-automated-cherrypicking-js": () => import("./../../../src/pages/automated-cherrypicking.js" /* webpackChunkName: "component---src-pages-automated-cherrypicking-js" */),
  "component---src-pages-automated-dna-normalization-js": () => import("./../../../src/pages/automated-dna-normalization.js" /* webpackChunkName: "component---src-pages-automated-dna-normalization-js" */),
  "component---src-pages-automated-lab-applications-js": () => import("./../../../src/pages/automated-lab-applications.js" /* webpackChunkName: "component---src-pages-automated-lab-applications-js" */),
  "component---src-pages-automated-serial-dilution-js": () => import("./../../../src/pages/automated-serial-dilution.js" /* webpackChunkName: "component---src-pages-automated-serial-dilution-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-support-js": () => import("./../../../src/pages/contact-support.js" /* webpackChunkName: "component---src-pages-contact-support-js" */),
  "component---src-pages-customers-js": () => import("./../../../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-modules-heater-shaker-module-js": () => import("./../../../src/pages/modules/heater-shaker-module.js" /* webpackChunkName: "component---src-pages-modules-heater-shaker-module-js" */),
  "component---src-pages-modules-hepa-module-js": () => import("./../../../src/pages/modules/hepa-module.js" /* webpackChunkName: "component---src-pages-modules-hepa-module-js" */),
  "component---src-pages-modules-index-js": () => import("./../../../src/pages/modules/index.js" /* webpackChunkName: "component---src-pages-modules-index-js" */),
  "component---src-pages-modules-magnetic-module-js": () => import("./../../../src/pages/modules/magnetic-module.js" /* webpackChunkName: "component---src-pages-modules-magnetic-module-js" */),
  "component---src-pages-modules-temperature-module-js": () => import("./../../../src/pages/modules/temperature-module.js" /* webpackChunkName: "component---src-pages-modules-temperature-module-js" */),
  "component---src-pages-modules-thermocycler-module-js": () => import("./../../../src/pages/modules/thermocycler-module.js" /* webpackChunkName: "component---src-pages-modules-thermocycler-module-js" */),
  "component---src-pages-nucleic-acid-cleanup-js": () => import("./../../../src/pages/nucleic-acid-cleanup.js" /* webpackChunkName: "component---src-pages-nucleic-acid-cleanup-js" */),
  "component---src-pages-ot-2-[page]-js": () => import("./../../../src/pages/ot-2/[page].js" /* webpackChunkName: "component---src-pages-ot-2-[page]-js" */),
  "component---src-pages-ot-2-index-js": () => import("./../../../src/pages/ot-2/index.js" /* webpackChunkName: "component---src-pages-ot-2-index-js" */),
  "component---src-pages-ot-app-js": () => import("./../../../src/pages/ot-app.js" /* webpackChunkName: "component---src-pages-ot-app-js" */),
  "component---src-pages-pcr-sample-prep-js": () => import("./../../../src/pages/pcr-sample-prep.js" /* webpackChunkName: "component---src-pages-pcr-sample-prep-js" */),
  "component---src-pages-pipettes-js": () => import("./../../../src/pages/pipettes.js" /* webpackChunkName: "component---src-pages-pipettes-js" */),
  "component---src-pages-protocols-designer-js": () => import("./../../../src/pages/protocols/designer.js" /* webpackChunkName: "component---src-pages-protocols-designer-js" */),
  "component---src-pages-protocols-js": () => import("./../../../src/pages/protocols.js" /* webpackChunkName: "component---src-pages-protocols-js" */),
  "component---src-pages-request-protocol-js": () => import("./../../../src/pages/request-protocol.js" /* webpackChunkName: "component---src-pages-request-protocol-js" */),
  "component---src-pages-roi-[calculator]-[application]-js": () => import("./../../../src/pages/roi/[calculator]/[application].js" /* webpackChunkName: "component---src-pages-roi-[calculator]-[application]-js" */),
  "component---src-pages-roi-[calculator]-js": () => import("./../../../src/pages/roi/[calculator].js" /* webpackChunkName: "component---src-pages-roi-[calculator]-js" */),
  "component---src-pages-roi-users-[role]-js": () => import("./../../../src/pages/roi/users/[role].js" /* webpackChunkName: "component---src-pages-roi-users-[role]-js" */),
  "component---src-pages-roi-users-js": () => import("./../../../src/pages/roi/users.js" /* webpackChunkName: "component---src-pages-roi-users-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-workstations-automated-ngs-library-prep-js": () => import("./../../../src/pages/workstations/automated-ngs-library-prep.js" /* webpackChunkName: "component---src-pages-workstations-automated-ngs-library-prep-js" */),
  "component---src-pages-workstations-automated-nucleic-acid-extraction-js": () => import("./../../../src/pages/workstations/automated-nucleic-acid-extraction.js" /* webpackChunkName: "component---src-pages-workstations-automated-nucleic-acid-extraction-js" */),
  "component---src-pages-workstations-expanded-ngs-workstation-js": () => import("./../../../src/pages/workstations/expanded-ngs-workstation.js" /* webpackChunkName: "component---src-pages-workstations-expanded-ngs-workstation-js" */),
  "component---src-pages-workstations-pcr-js": () => import("./../../../src/pages/workstations/pcr.js" /* webpackChunkName: "component---src-pages-workstations-pcr-js" */),
  "component---src-pages-workstations-protein-purification-bead-based-js": () => import("./../../../src/pages/workstations/protein-purification-bead-based.js" /* webpackChunkName: "component---src-pages-workstations-protein-purification-bead-based-js" */),
  "component---src-pages-workstations-protein-purification-chromatography-js": () => import("./../../../src/pages/workstations/protein-purification-chromatography.js" /* webpackChunkName: "component---src-pages-workstations-protein-purification-chromatography-js" */)
}

